"use client";

import {
  Form,
  Input,
  Checkbox,
  Button,
  Text,
  theme,
  FormProps,
  useNotificationContext,
} from "@/src/pattern-library";
import { useTranslations } from "next-intl";
import { z } from "zod";
import { useState } from "react";
import { segmentEventLoginFailed } from "../segment";
import { segmentClient } from "@/src/utils/segment";
import { supplierRoutes } from "@/src/constants/routes";
import { useSearchParams, useRouter } from "next/navigation";
import { useServerAction } from "@/src/hooks/useServerAction";
import { Namespace } from "@/src/i18n/namespace";
import { loginAction } from "@/src/actions/authentication/loginAction";

interface LoginFieldType {
  email?: string;
  password?: string;
  remember?: string;
}

const LoginForm = () => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const [loading, setLoading] = useState(false);
  const t = useTranslations(Namespace.AUTH);
  const tCommon = useTranslations(Namespace.COMMON);
  const tGlobal = useTranslations(Namespace.GLOBAL);
  const {
    token: { sizeLG },
  } = theme.useToken();
  const { notification } = useNotificationContext();

  const callLoginAction = useServerAction(loginAction);

  const login: FormProps<LoginFieldType>["onFinish"] = async (values) => {
    if (loading) {
      return;
    }

    setLoading(true);
    try {
      const loginResult = await callLoginAction({
        email: values.email || "",
        password: values.password || "",
      });

      if (loginResult?.errorCode) {
        const errMsg = tGlobal(`error.${loginResult.errorCode}`);

        notification.error({
          message: errMsg || "",
        });

        segmentEventLoginFailed(values?.email || "");
        setLoading(false);
      } else if (loginResult.data?.user) {
        const { user, onBoardFinished } = loginResult.data;
        segmentClient.login({ user, remember: !!values.remember });
        await new Promise((res) => {
          setTimeout(res, 300);
        }); // Adding some buffer for the below segment event

        const getRedirectionUrl = () => {
          const redirectParam = searchParams?.get("redirect");
          if (redirectParam) return redirectParam;
          if (onBoardFinished) return supplierRoutes.dashboard;
          return supplierRoutes.gettingStarted.index;
        };

        window.location.href = getRedirectionUrl();
      }
    } catch (error) {
      setLoading(false);
      segmentEventLoginFailed(values?.email || "");
    }
  };

  return (
    <Form
      style={{ marginTop: sizeLG }}
      layout="vertical"
      initialValues={{ remember: true }}
      onFinish={login}
    >
      <Form.Item<LoginFieldType>
        label={t("login.email")}
        name="email"
        rules={[
          { required: true, message: t("validation.emailInvalid") },
          () => ({
            validator(_, value) {
              if (!value) {
                return Promise.resolve();
              }

              const isValid = z
                .string()
                .email()
                .safeParse(value.replace(/ /g, "")).success;

              if (isValid) {
                return Promise.resolve();
              }

              return Promise.reject(new Error(t("validation.emailInvalid")));
            },
          }),
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item<LoginFieldType>
        label={t("login.password")}
        name="password"
        rules={[
          {
            required: true,
            message: tCommon("validation.isRequired", {
              id: t("login.password"),
            }),
          },
        ]}
      >
        <Input.Password />
      </Form.Item>

      <div className="flex justify-between">
        <Form.Item<LoginFieldType> name="remember" valuePropName="checked">
          <Checkbox>{t("login.rememberMe")}</Checkbox>
        </Form.Item>

        <Button
          type="link"
          onClick={() => {
            router.push(supplierRoutes.login.forgotPassword);
          }}
        >
          {t("login.forgotPassword")}
        </Button>
      </div>

      <Form.Item>
        <Button disabled={loading} block type="primary" htmlType="submit">
          {t("login.signIn")}
        </Button>
      </Form.Item>

      <div className="text-center">
        {t.rich("login.signUpMessage", {
          // eslint-disable-next-line react/no-unstable-nested-components
          account: (chunks) => (
            <div>
              <Text>{chunks}</Text>
            </div>
          ),
          // eslint-disable-next-line react/no-unstable-nested-components
          signUp: (chunks) => (
            <Button
              type="link"
              onClick={() => {
                window.location.href = supplierRoutes.bookDemo;
              }}
            >
              {chunks}
            </Button>
          ),
        })}
      </div>
    </Form>
  );
};

export default LoginForm;
